<template>
<div class="container">
    <div class="create-title">
        <div v-if="editForm.id">编辑兑换码</div>
        <div v-else>新建兑换码</div>
        <div class="btn">
            <el-button @click="routeChange(1)">取消</el-button>
            <el-button type="primary" @click="handleConfirm">保存</el-button>
        </div>
    </div>
    <div class="create-container">
        <colorful-title title="基本信息" class="pd-custom" />
        <div class="form-item">
            <div class="key">兑换码名称</div>
            <el-input class="value" v-model="editForm.name" placeholder="请输入兑换码名称"/>
        </div>
        <div class="form-item">
            <div class="key">库存</div>
            <el-input class="value" v-model="editForm.count" :disabled='countDisabled' placeholder="请输入库存数量">
                <template slot="append">个</template>
            </el-input>
        </div>
        <div class="form-item">
            <div class="key">活动时间</div>
            <el-date-picker class="value" v-model="editForm.activity_time" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </div>
        <div class="form-item">
            <div class="key">每人可用</div>
            <el-radio-group class="value" v-model="editForm.use" @change="isChangeInput">
                <el-radio :label="1">不限制</el-radio>
                <el-radio :label="2">
                    <el-input v-model="editForm.num" :disabled='disabled' placeholder="请输入个数">
                        <template slot="append">个兑换码</template>
                    </el-input>
                </el-radio>
            </el-radio-group>
        </div>
        <colorful-title title="兑换权益" class="pd-custom" />
        <div class="form-item mb-20">
            <div class="key">兑换商品</div>
            <select-course content='新增商品' class="add-field" dialogTitle='添加兑换商品' :showColumn="true" @submit='handleCourseSubmit' :items='commodityList'/>
            <div class="remark">最多添加1个商品</div>
        </div>
        <div class="column">
            <div v-for="(item, index) in commodityList" :key="index" class="commodity">
                <div class="course-cover">
                    <el-image :src="IMG_URL_PREFIX + item.cover" fit='cover' class="course-cover-img"
                        v-if="item.cover">
                        <div slot="error" class="error iconfont icon-p-image"></div>
                    </el-image>
                    <div class="error iconfont icon-p-image" v-else></div>
                </div>
                <div class="column-1">
                    <div class="name">{{ item.name }}</div>
                    <div class="price">¥{{ item.price / 100}}</div>
                    <div class="type">商品类型：{{ item.media_type | courseTypeFilter }}</div>
                </div>
                <img src="@/assets/images/delete.png" alt="" class="delete" @click="handleDelete(index)">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Core from '@/core';
export default {
    components: {
        ColorfulTitle: () => import('@/components/common/ColorfulTitle'),
        SelectCourse: () => import('@/components/online-edu/SelectCourse')
    },
    data() {
        return {
            IMG_URL_PREFIX: Core.Const.NET.FILE_URL,
            editForm: {
                id: '',
                name: '',
                count: '',
                activity_time: [],
                use: 0,
                num: '',
                ids: ''
            },
            disabled: true,
            countDisabled: false,
            checkList: [],
            // 新增字段
            detail: '',
            commodityList: []
        };
    },
    watch: {},
    created() {
        this.editForm.id = this.$route.query.id;
    },
    computed: {},
    mounted() {
        if (this.editForm.id) {
            this.getDetail();
            this.countDisabled = true;
        }
    },
    methods: {
        getDetail() {
            Core.Api.ExChangeCode.detail(this.editForm.id).then(
                (res) => {
                    console.log('res', res)
                    this.editForm.name = res.detail.name;
                    this.editForm.count = res.detail.count;
                    this.detail = res.detail;
                    this.editForm.activity_time = [res.detail.begin_time * 1000, res.detail.end_time * 1000]
                    this.getCommodityList(res.detail.target_id);
                    if(res.detail.user_per_count == 0) {
                        this.disabled = true;
                        this.editForm.use = 1;
                    }else {
                        this.disabled = false;
                        this.editForm.use = 2;
                        this.editForm.num = res.detail.user_per_count;
                    }
                }
            );
        },
        async getCommodityList(ids) {
            let commodityList = []
            await Core.Api.Course.detail(ids).then(res => {
                    commodityList.push(res.detail)
                })
            this.commodityList = commodityList
            console.log('this.commodityList', this.commodityList)
        },
        isChangeInput() {
            if (this.editForm.use === 2) {
                this.disabled = false;
            } else {
                this.disabled = true;
                this.editForm.num = '';
            }
        },
        routeChange(type) {
            switch (type) {
                case 1:
                    this.$router.go(-1);
                    break;
            }
        },
        handleCourseSubmit({ ids, items }) {
            if (ids.length > 100) {
                return this.$message.warning('最多添加100个商品');
            }
            console.log('handleCourseSubmit ids:', ids);
            this.commodityList = [items.pop()];
        },
        handleDelete(index) {
            this.commodityList.splice(index, 1);
        },
        handleConfirm() {
            console.log('editForm:', this.editForm);
            this.editForm.ids = this.commodityList.map((item) => item.id);
            if (!this.editForm.name) {
                return this.$message.warning('请输入兑换码名称');
            }
            if (!this.editForm.count) {
                return this.$message.warning('请输入库存数量');
            }
            if (!this.editForm.activity_time[0]) {
                return this.$message.warning('请选择活动时间');
            }
            if (this.editForm.ids.length < 1) {
                return this.$message.warning('请至少选择一项兑换商品');
            }
            if (this.editForm.ids.length > 1) {
                return this.$message.warning('最多可选一项兑换商品');
            }
            Core.Api.ExChangeCode.save(
                this.editForm.id,
                this.editForm.name,
                this.editForm.count,
                this.editForm.activity_time[0] / 1000,
                this.editForm.activity_time[1] / 1000,
                this.editForm.num || 0,
                this.editForm.ids.join(',')
            ).then((res) => {
                this.$message.success('保存成功');
                this.routeChange(1);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    padding: 0 30px 40px;
    .create-title {
        height: 62px;
        line-height: 62px;
        font-size: 20px;
        font-weight: 500;
        color: #323538;
        display: flex;
        justify-content: space-between;
    }
    .create-container {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e1e7ec;
        overflow: hidden;
        padding: 0 60px 60px;
        box-sizing: border-box;

        .pd-custom {
            padding: 30px 0;
        }
        .form-item {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;

            + .form-item {
                margin-top: 16px;
            }

            .key {
                width: 72px;
            }
            .value {
                width: 500px;
                margin-left: 16px;
            }
            .remark {
                font-size: 14px;
                color: #d8d8d8;
                margin-left: 8px;
            }
            &.mb-20 {
                margin-bottom: 20px;
            }
        }
        .column {
            display: flex;
            flex-direction: column;
            padding: 0 235px 0 10px;
            .commodity {
                height: 90px;
                background: #f7f9fa;
                border-radius: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 20px;
                padding-left: 20px;
                position: relative;
                .course-cover {
                    width: 80px;
                    height: 60px;
                    background: #dee5ea;
                    border-radius: 1px;
                    overflow: hidden;
                    margin-right: 20px;
                    text-align: center;
                    line-height: 60px;
                    .course-cover-img {
                        width: 100%;
                        height: 100%;
                    }
                    .error {
                        font-size: 14px;
                    }
                }
                .delete {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    right: -6px;
                    top: -6px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .column-1 {
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-size: 14px;
                        font-weight: 400;
                        color: #363d42;
                        margin-bottom: 6px;
                    }
                    .price {
                        font-size: 10px;
                        font-weight: 400;
                        color: #1D517E;
                        margin-bottom: 6px;
                    }
                    .type {
                        font-size: 12px;
                        font-weight: 400;
                        color: #b2b2b2;
                    }
                }
            }
        }

        .custom-add {
            display: flex;
            flex-direction: column;
            .row {
                display: flex;
                flex-direction: row;
            }
            .custom-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px 20px 70px;
                width: 582px;
                .check {
                    font-size: 12px;
                    font-weight: 400;
                    color: #323538;
                }
                .key {
                    font-size: 12px;
                    font-weight: 400;
                    color: #323538;
                    width: 160px;
                }
                .value {
                    width: 280px;
                    background: #f6f7fa;
                    font-size: 12px;
                    font-weight: 400;
                    color: #323538;
                }
            }
        }
        .add-field {
            margin-left: 20px;
        }
    }
}
</style>

<style lang="scss">
.create-container {
    .set-item {
        .el-select .el-input {
            font-size: 12px;
        }
    }
}
</style>
